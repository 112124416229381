.home-background {
  background: url('./background.png') no-repeat center center fixed;
  background-size: cover;
  font-family: 'Arial', sans-serif;
}

main {
  padding: 20px;
}

/* Section styles for the text and image layout */
.section {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: center; /* Center-align children */
  margin-bottom: 40px;
  padding: 20px;
  background: #fff; /* White background for readability */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Section title styles */
.section-title {
  text-align: center;
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
  text-decoration: underline;
}

/* Styles for image and paragraph within a section */
.section img {
  max-width: 100%; /* Full width of the section */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Space between image and text */
}

.section p {
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  text-align: center; /* Center-align text */
}

/* Button styles */
button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  margin-top: 20px; /* Space between text and button */
}

button:hover {
  background-color: #0056b3;
}


/* Responsive design */
@media (max-width: 768px) {
  .section {
    flex-direction: column;
    text-align: center;
  }

  .section img {
    max-width: 100%;
    margin: 0 0 20px 0;
  }

  .section p {
    max-width: 100%;
  }
}
