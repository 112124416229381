
nav {
  background-color: #000000; /* Dark background for contrast */
  padding: 0 40px; /* Consistent horizontal padding with the header */
  display: flex; /* Using flexbox for nav layout */
  justify-content: center; /* Centering the links */
  align-items: center; /* Align items for vertical centering */
}

nav a {
  color: white;
  padding: 16px 20px; /* Larger padding for easier click targets */
  text-decoration: none;
  font-size: 1rem; /* Use rem for font sizes for scalability */
  font-weight: 400; /* Less bold for a sleeker look */
  margin: 0 8px; /* Reduced margin for a tighter nav */
  transition: color 0.3s, background-color 0.3s; /* Smooth transitions for hover effects */
  border-radius: 4px; /* Subtle rounded corners for modern feel */
}

nav a:hover, nav a:focus {
  color: #4CAF50; /* Color change on hover */
  background-color: rgba(255, 255, 255, 0.1); /* Slight background on hover for visibility */
}

@media (max-width: 768px) {
  nav {
    padding: 10px 20px; /* Adjust padding on smaller screens */
  }

  nav a {
    padding: 10px 12px; /* Adjust link padding on smaller screens */
    margin: 0 4px; /* Reduce margin between links */
  }
}