/* Custom styles for mobile responsiveness */
@media (max-width: 768px) {
  /* Adjust styles for elements that might be affected by the chat widget */
  .affected-element {
    padding-bottom: 100px; /* Provide extra space at the bottom for the chat widget */
  }

  /* You can also hide certain non-critical elements to make more room for the chat widget */
  .less-important-element {
    display: none;
  }

  /* Adjust the chat widget button if it's part of the DOM and not within the iframe */
  .tawk-button {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1000;
  }
}

/* Ensure that your body or main container takes the full height of the viewport */
body, .main-container {
  min-height: 100vh;
  position: relative;
  padding-bottom: 100px; /* Extra padding to account for the chat widget */
  box-sizing: border-box; /* Makes sure padding doesn't add to the width */
}
