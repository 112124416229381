/* footer.css */

footer {
  background-color: #2c3e50; /* Dark background for the footer */
  color: #fff; /* White text color */
  text-align: center; /* Center aligning the content */
  padding: 20px 10px; /* Padding for top and bottom, reduced side padding for mobile */
  font-size: 14px; /* Font size for the text */
  line-height: 1.5; /* Line height for better readability */
}

footer a {
  color: #fff; /* White text color for links */
  text-decoration: none; /* Removing underline from links */
  margin: 0 10px; /* Horizontal spacing between links */
  transition: color 0.3s; /* Smooth transition for color change */
}

footer a:hover {
  color: #f39c12; /* Color change on hover, can be adjusted */
}

#social-media-links {
  margin-top: 10px; /* Space above the social media links */
}

#social-media-links a {
  padding: 5px 10px; /* Padding around social media links */
  display: inline-block; /* Ensuring that the links are properly aligned */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  footer {
    padding: 20px 5px; /* Smaller padding on the sides for narrow screens */
    font-size: 12px; /* Smaller font size for better fit on screen */
  }

  footer a {
    margin: 0 5px; /* Reduced margin between links for smaller screens */
    font-size: 12px; /* Smaller font size for the links */
  }

  #social-media-links a {
    padding: 5px; /* Reduced padding around social media links */
  }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  footer {
    padding: 15px 5px; /* Further reduced padding on very small screens */
  }

  footer a {
    display: block; /* Stack the links vertically */
    margin: 5px 0; /* Adjust margin for vertical layout */
  }

  #social-media-links {
    margin-top: 15px; /* More space above the social media links */
  }

  #social-media-links a {
    margin-bottom: 5px; /* Space between social media links when stacked */
  }
}
