/* Reset some default styles */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* Add some spacing and formatting to general elements */
body {
    line-height: 1;
    font-family: Arial, sans-serif;
    overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Main app container */
.App {
    max-width: 100%;
    margin: 0 auto;
    overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Styles for images to be responsive */
img {
    max-width: 100%;
    height: auto;
}

/* Styles for sections */
.section {
    padding: 20px;
    text-align: center;
}

.section-title {
    margin-bottom: 15px;
    font-size: 24px;
    color: #333;
}

/* Media Queries for smaller screens */
@media (max-width: 768px) {
    .section {
        padding: 10px;
    }
    .section-title {
        font-size: 20px;
    }
    /* Add more responsive adjustments as needed */
}

/* Add additional styles as required */
