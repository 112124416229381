/* CSS styles for the Contact component */

.home-background {
  background-image: url('./background.png'); /* Adjust the path as needed */
  background-size: cover; /* Adjust this to your preference */
  background-repeat: no-repeat;
  background-attachment: fixed; /* You can remove this if you don't want a fixed background */
}

/* Contact Information */
#contact-info {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

#contact-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}

/* Contact Form */
#contact-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

#contact-form h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

form input[type="text"],
form input[type="email"],
form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

form input[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

form input[type="submit"]:hover {
  background-color: #0056b3;
}

/* Map Location */
#map-location {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
}

#map-location h2 {
  font-size: 24px;
  margin-bottom: 10px;
}
