
.home-background {
  background-image: url('./background.png'); /* Adjust the path as needed */
  background-size: cover; /* Adjust this to your preference */
  background-repeat: no-repeat;
  background-attachment: fixed; /* You can remove this if you don't want a fixed background */
}


h1 {
  font-size: 36px;
}

header p {
  font-size: 18px;
  margin-top: 10px;
}

/* Style the content section */
.careers {
  max-width: 800px;
  background-color: white;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.careers h2 {
  font-size: 24px;
  margin-top: 20px;
}

.careers p {
  font-size: 16px;
  line-height: 1.5;
}

/* Style the form section */
.apply-info {
  margin-top: 30px;
}

.email-contact {
  margin-top: 20px;
}

.email-link {
  display: inline-block;
  margin-left: 5px;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
}

.email-link:hover {
  text-decoration: none;
  color: #0056b3;
}


/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .careers {
    padding: 10px;
  }

  header h1 {
    font-size: 28px;
  }

  header p {
    font-size: 16px;
  }
}
