/* CSS styles for the Apply component */

.home-background {
  background-image: url('./background.png'); /* Adjust the path as needed */
  background-size: cover; /* Adjust this to your preference */
  background-repeat: no-repeat;
  background-attachment: fixed; /* You can remove this if you don't want a fixed background */
}

/* Apply Form Container */
main {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

/* Headings */
h1 {
  font-size: 28px;
  margin-bottom: 10px;
}

h2 {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Labels and Inputs */
label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

textarea {
  height: 150px; /* Adjust the height as needed */
}

/* Checkbox */
input[type="checkbox"] {
  margin-right: 5px;
}

/* Submit Button */
input[type="submit"] {
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #0056b3;
}

/* Additional Notes */
p {
  margin-top: 20px;
  font-size: 16px;
}
