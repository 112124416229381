/* Use a mobile-first approach for responsive design */
body,
html {
  font-family: Arial, sans-serif;
  background: #f4f4f4;
  color: #333;
  line-height: 1.6;
  font-size: 16px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
}

/* Structure and layout */
.donation-container {
  max-width: 100%;
  padding: 20px;
  margin: 0 auto;
}

.credit-card-form,
.crypto-form {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Form Elements */
input[type='text'],
input[type='email'],
input[type='number'],
input[type='submit'],
.StripeElement,
.custom-amount-input,
.crypto-dropdown,
.copy-address-button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type='submit'] {
  background-color: #4caf50;
  color: white;
  border: 0;
  cursor: pointer;
}

input[type='submit']:disabled {
  background-color: #999;
  cursor: not-allowed;
}

/* Button styles */
button {
  background-color: #556cd6;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1em;
}

button:hover {
  background-color: #43458b;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Utility classes */
.hidden {
  display: none;
}

/* Responsive styles */
@media (max-width: 768px) {
  .donation-container {
    padding: 10px;
  }

  .credit-card-form,
  .crypto-form {
    padding: 15px;
    margin-bottom: 15px;
  }

  h3 {
    font-size: 1.2em;
  }

  input[type='submit'],
  button {
    padding: 10px 20px;
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  h3 {
    font-size: 1em;
  }

  input,
  .StripeElement,
  .custom-amount-input,
  .crypto-dropdown,
  .copy-address-button {
    padding: 8px;
    font-size: 0.8em;
  }

  button {
    padding: 8px 15px;
  }
}

/* To prevent horizontal scrolling caused by an overflow */
body {
  overflow-x: hidden;
}

/* Fix for the white space on the right on mobile */
.donation-amounts,
.credit-card-form,
.crypto-form {
  overflow: hidden;
}
