/* donate.css */

.home-background {
  background-image: url('./background.png'); /* Adjust the path as needed */
  background-size: cover; /* Adjust this to your preference */
  background-repeat: no-repeat;
  background-attachment: fixed; /* You can remove this if you don't want a fixed background */
}


main {
  padding: 2rem;
  text-align: center;
}

#donation-section {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 2rem;
  margin: 2rem auto;
  max-width: 600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* donate.css */

/* Styles for the section with id "welcome-section" */
#welcome-section {
  background-color: #f7f7f7; /* Background color */
  padding: 20px; /* Padding for content */
  text-align: center; /* Center-align text */
}

#welcome-section h2 {
  color: #333; /* Header text color */
}

#welcome-section p {
  color: #555; /* Paragraph text color */
  margin: 10px 0; /* Margin for paragraphs */
}


h2 {
  color: #333;
  margin-bottom: 1rem;
}

p {
  color: #666;
  line-height: 1.6;
}

/* Add additional styles for navigation, footer, header, and form */
