header {
  background-color: #000000; /* Black background for the header */
  padding: 16px 40px; /* Ample padding for a spacious look */
  display: flex;
  align-items: center;
  justify-content: center; /* Center all content */
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); /* More pronounced shadow for depth */
  position: relative; /* Needed for absolute positioning of quote */
}

/* Styles for the quote */
.quote {
  color: white; /* White color for the quote text */
  font-style: italic; /* Italicize the quote */
  max-width: 30%; /* Narrower maximum width for the quote block */
  position: absolute; /* Absolute positioning */
  left: 16px; /* Position from the left */
  top: 50%; /* Vertically center */
  transform: translateY(-50%); /* Adjust for true centering */
}

.quote p {
  margin: 0; /* Remove default paragraph margins */
}

.author {
  text-align: right; /* Align the author's name to the right */
  font-weight: bold; /* Bold for author's name */
}

header a {
  text-decoration: none;
  color: white;
  font-weight: 500; /* Modern and not too bold */
  transition: color 0.3s; /* Smooth transition for color change */
}

header img {
  height: 160px; /* Increase the size of the logo */
  width: auto; /* Maintain aspect ratio of the logo */
}

/* Responsive adjustments for the logo */
@media (max-width: 768px) {
  header img {
    height: 60px; /* Slightly smaller logo for smaller screens */
  }
}

/* Additional responsive adjustments if necessary */
@media (max-width: 480px) {
  header {
    padding: 10px 20px; /* Smaller padding for very small screens */
  }
  
  header img {
    height: 50px; /* Smaller logo for very small screens */
  }

  .quote {
    max-width: 100%; /* Allow quote to take full width on small screens */
    position: static; /* Use static positioning on small screens */
    transform: none; /* Reset transform */
  }
}
